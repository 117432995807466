import MyGallery from "../../../components/ImgGallery";
import { motion } from 'framer-motion';
import { CardMedia, Grid } from '@mui/material'
import "../projects.css"
import {AE_images} from "../../../images/projects/american_eagle/AE_images";

export default function AmericanEagle() {
    return (<section className="projects">
        <h3 className="pageTitle">Fiberglass Repair and Restoration</h3>
        <motion.div
            initial={{ x: "-30vw", opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.4, ease: "easeInOut" }}
        >

            {/* <button type="button" onClick={handleBack}>back</button> */}
            <div className="project-details">
            <div>
                <Grid
                    container
                    direction="row-reverse"
                >
                    <Grid items xs={12} l={5} >
                    </Grid>
                    <div>
                        <p>In this project I restore the damaged panels of the motorhome, by doing a fiberglass repair with some bodywork. </p>
                        <h4>The Challenge</h4>
                        <p>
                            The motorhome had significant damage with missing sections of bumper and fender, alongside minor damage to cargo doors.
                        </p>

                        <h4> The Process:</h4>
                        <h5>Bumper Restoration</h5>
                        <p>
                            <b>1.	Expanding Foam Positive Mold:</b> The missing corner of the bumper was reconstructed using 2-parts expanding foam, sculpted to restore the original shape.
                        </p>
                        <p>
                            <b>2.	Fiberglass Layup:</b> Fiberglass chopped strand was applied on the top to create the shell. Once cured, foam was removed and the inside was reinforced with several more chopped strand layers.
                        </p>
                        <p>
                            <b> 3.	Bondo, Primer and Painting:</b> Bondo was applied to smooth out imperfections and refine details. Followed by a coat of primer. The whole bumper was lightly sanded and painted to match original style, finished with 3 layers of clearcoat.
                        </p>

                        <h5>Fender Restoration</h5>
                        <p>
                            <b>1.	Negative Mold:</b> To reconstruct the missing fender, I utilised an old motorhome cargo door to craft a negative mold. Intricate details were cloned from other parts of the motorhome. Also utilised sculpting clay to recreate details that could not otherwise be copied from other side of motorhome.
                        </p>
                        <p>
                            <b>2.	Fiberglass Layup:</b> First few layers were done with fiberglass cloth for added strength, backed with chopped strand to build up thickness to match the original fender. 
                        </p>
                        <p>
                            <b> 3.	Paint Job:</b> The fender presented an added challenge with a complex original paint job featuring multiple shades of similar colours and streaks.
                        </p>
                        <h4> Before and After:</h4>
                        <p>Explore the transformative journey through a series of images showcasing the motorhome's condition before, during, and after the restoration process.</p>
                    </div>
                </Grid>
            </div>
            <div>
                <MyGallery images = {AE_images}></MyGallery>
            </div>

            </div>
        </motion.div>
    </section>)
}
