import { motion } from "framer-motion";
import ProjectCard from "./ProjectCard";
import "./projects.css";
import wisePOS_Img from "../../images/projects/taptodonate/WisePOS_Benevity.png";
import kinesis_Img from "../../images/projects/kinesis_keyboard/kinesis_stock.png";
import americanEalge_Img from "../../images/projects/american_eagle/ae_0.png";
import genset_Img from "../../images/projects/genset/genset.png";
export default function Projects (){
  return (
    <section className="projects">
      <h3 className="pageTitle">Projects</h3>
      <motion.div
        initial={{ y: "30vw", opacity: 0 }}
        animate={{y:0, opacity :1}}
        transition={{ duration: 0.4, ease: "easeInOut" }}
        >
          <div>
        <ProjectCard
          img= {wisePOS_Img}
          img_alt="Tap to Donate - Stripe WisePOS"
          title="Benevity - Tap To Donate"
          text="An integration with Stripe POS sytem to collect donations. Including an administrator dashboard to monitor and manage deployed devices."
          nav_link="tap-to-donate"
        ></ProjectCard>
        <ProjectCard
          img= {kinesis_Img}
          img_alt="Kinesis Advantage QMK Mod"
          title="Kinesis Advantage QMK mod"
          text="Keyboard upgrade with Teensy controller and custom QMK firmware. Key switches replacement and overhaul of F-row keys."
          nav_link="kinesis-advantage"
        ></ProjectCard>
        <ProjectCard
          img= {americanEalge_Img}
          img_alt="Fiberglass repair"
          title="Fiberglass Repair & Restoration"
          text="Motorhome's front bumper and fender restoration - a project that involved fiberglass repair and body restoration."
          nav_link="american-eagle"
        ></ProjectCard>
        <ProjectCard
          img= {genset_Img}
          img_alt="Genset"
          title="Diesel Generator Conversion"
          text="Conversion of 8kW kubota diesel generator to work in a motorhome by installing a speed governor and genset controller."
          nav_link="diesel-genset"
        ></ProjectCard>
        </div>
      </motion.div>
    </section>
  );
};