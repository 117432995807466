import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import {Card, CardMedia, Grid } from '@mui/material'
import { CardActionArea } from '@mui/material';

export default function ProjectCard({img,img_alt,title,text,nav_link}) {
  const navigate = useNavigate();
  const handleClick =()=>navigate(nav_link)
  return (
    <div className='project-card'>
      <Card >
        <CardActionArea 
          onClick={handleClick}
        >
          <Grid
              container 
              direction="row-reverse"
          >
            <Grid 
              items xs={12} sm={5} lg={4}
            >
              <div className='image'>
                <CardMedia
                  sx={{
                    objectFit:"contain"
                  }}
                  component="img"
                  height="250"
                  image={img}
                  alt={img_alt}
                  align-items="center"
                />
              </div>
            </Grid>

            <Grid
              items xs={12} sm={7} lg={8}
            >
              <div className="card-content">
                <h4>{title}</h4>
                <p>{text}</p>
              </div>
            </Grid>
          </Grid>
        </CardActionArea>
      </Card >
    </div>
  );
}