import Header from "./components/Header";
import AnimatedRoutes from "./components/AnimatedRoutes";

function App() {
  return (
    <>
      <Header />
      <AnimatedRoutes/>
    </>
  );
}

export default App;
