import MyGallery from "../../../components/ImgGallery";
import { motion } from "framer-motion";
import { CardMedia, Grid } from "@mui/material";
import "../projects.css";
import { Kinesis1_images, Kinesis2_images, } from "../../../images/projects/kinesis_keyboard/Kinesis_images";

export default function KinesisAdvantage() {
  return (
    <section className="projects">
      <h3 className="pageTitle">Kinesis Advantage mods</h3>
      <motion.div
        initial={{ x: "30vw", opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        // initial={{ y: "30vw", opacity: 0 }}
        // animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.4, ease: "easeInOut" }}
      >
        <div className="project-details">
          <div>
            <Grid container direction="row-reverse">
              <Grid items xs={12} l={7}>
                <div className="image">
                  <CardMedia
                    sx={{
                      objectFit: "contain",
                    }}
                    component="img"
                    height="500"
                    image={require("../../../images/projects/kinesis_keyboard/kinesis1&2.png")}
                    alt="Tap to donate - Stripe WisePOS"
                    align-items="center"
                  />
                </div>
              </Grid>

              <Grid items xs={12} l={5}>
                <div className="card-content">
                  <p>
                    I embarked on the journey of transforming my Kinesis
                    Advantage 2 keyboard to suit my preferences. This project
                    involved replacing stock controller, flashing custom firmware, replacing original key switches
                     and other minor usability upgrades.
                  </p>
                </div>
              </Grid>
              <div>
                <h4>Kinesis Advantage 2:</h4>
                <p>
                  <b>1. Controller and Firmware: </b> I wanted to personalise my
                  keyboard and become more efficient and increase productivity.
                  While the original controller does support multiple features
                  such as key remapping and macros, it's not as feature rich and
                  customizable as <a href="https://qmk.fm/">QMK</a>. The
                  problem, of course, is that it is not possible to run QMK
                  firmware on stock Kinesis controller. The solution:{" "}
                  <a href="https://michael.stapelberg.ch/posts/2020-07-09-kint-kinesis-keyboard-controller/">
                    {" "}
                    Stapelberg mod
                  </a>
                  . I got the components: Teensy4.1, bare PCB and other
                  connectors and got to soldering. Baking a firmware was another
                  challenge, the online QMK tool doesn't yet work with Kinesis
                  keyboard, so I used a working fork of QMK firmware CLI and
                  manually wrote a keyboard layout configuration file. Flashing
                  it was quite simple with teensy-loader-cli.
                </p>
                <p>
                  <b>2. Switches replacement: </b> I never been a fan of tactile
                  switches, especially the stock Cherry Browns, I personally
                  found them to be too inconsistent and the general feeling was
                  very similar to one of a membrane keyboard or laptop ones.
                  After using a switch tester to try over 60 switches, I decided
                  to go with Kalith Box White. The light actuation force of
                  ~40g, and almost linear curve with a clicky bar, made it a
                  fantastic choice for a coding keyboard. I desoldered all
                  original Cherry MX switches and replaced them with new ones,
                  it took a bit of time as there are over 400 solder joints to
                  do.
                </p>
                <p>
                  <b>3. Quick disconnect cable:</b> Having multiple keyboards
                  for different purposes, made it pretty tedious to swap between
                  them. So I came up with a solution by soldering female USB
                  ports to all of my keyboards and using a cable with magnetic
                  tips. This made swapping keyboards very convenient and fast
                  when coding or gaming.
                </p>
                <p>
                  <b>4. Sound Dampening:</b> One annoying thing about Advantage
                  keyboard is the rather bulky empty shell that amplifies noise
                  of keypresses. To dampen the unwanted noise and make the
                  clicky sound of a switch sharper, I added a few layers of
                  different materials. I applied a layer of heavy rubber pad to
                  dampen the sound and a layer of foam to absorb other noises.
                  This changed the sound signature completely and as a bonus,
                  added weight made it feel much more premium and actually worth
                  its price, compared to stock.
                </p>
                <div>
                  <MyGallery images={Kinesis2_images}></MyGallery>
                </div>
                <h4>Kinesis Advantage:</h4>
                <p>
                  I recently bought a non-functional Kinesis Advantage keyboard
                  at a bargain, which was a perfect candidate to tinker with.
                  Just like in my first Kinesis keyboard, I replaced the
                  controller with KinT in order to run QMK firmware, replaced
                  connectors and added sound dampening.
                </p>
                <p>
                  Since top F-row keys were not working, it gave me an excuse to
                  take a dremel to the keyboard and make some room for full
                  sized mechanical switches. I designed and 3D printed plates to
                  insert mechanical switches and manually wired them to PCB. The
                  thumb clusters in the original Advantage keyboards use
                  different connectors that I didn't have at a time, so instead
                  I soldered them using staples, which conveniently had exact
                  spacing I needed. The end result turned out to pretty great
                  and top row is much more usable now, compared to tiny original
                  keys.
                </p>
                <p>
                  Next in line for upgrades is key switches. I'm planning to
                  upgrade it with a silent linear type switch, so I can use the
                  keyboard in the office without disturbing anyone.
                </p>
              </div>
            </Grid>
          </div>
          <div>
            <MyGallery images={Kinesis1_images}></MyGallery>
          </div>
        </div>
      </motion.div>
    </section>
  );
}
