import { Routes, Route, useLocation } from "react-router-dom";

import About from "../pages/about/About";
import Contact from "../pages/contact/Contact";
import Projects from "../pages/projects/Projects";
import TapToDonate from "../pages/projects/taptodonate/TapToDonate"
import KinesisAdvantage from "../pages/projects/kinesis_keyboard/KinesisAdvantage";
import AmericanEagle from "../pages/projects/american_eagle/AmericanEagle";
import Genset from "../pages/projects/genset/Genset";

export default function AnimatedRoutes(){
  const location = useLocation();

  return (
    <Routes location={location} key={location.pathname}>
      <Route path="/" element={ <About/> } />
      <Route path="/contact" element={ <Contact/> } />
      <Route path="/projects" element={<Projects/>} />
      <Route path="/projects/tap-to-donate" element={ <TapToDonate/> } />
      <Route path="/projects/kinesis-advantage" element={ <KinesisAdvantage/> } />
      <Route path="/projects/american-eagle" element={ <AmericanEagle/> } />
      <Route path="/projects/diesel-genset" element={ <Genset/> } />
    </Routes>
  );
};