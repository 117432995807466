export const Kinesis1_images= [
    {
      original: require('./kinesis2_2.jpg'),
      description: 'Original F-row',
    },
    {
      original: require('./kinesis1_6.jpg'),
      description: 'Modded F-row',
    },
    {
      original: require('./kinesis1_7.jpg'),
      description: 'Modded F-row',
    },
    {
      original: require('./kinesis1_8.jpg'),
      description: 'Modded F-row',
    },
    {
      original: require('./kinesis1_9.jpg'),
      description: 'Modded F-row',
    },
    {
      original: require('./kinesis1_1.jpg'),
      description: 'Teensy4.1 and bare PCB components',
    },
    {
      original: require('./kinesis1_2.jpg'),
      description: 'Soldered PCB components',
    },
    {
      original: require('./kinesis1_3.jpg'),
      description: 'Cut out keyboard shell',
    },
    {
      original: require('./kinesis1_4.jpg'),
      description: 'Wiring and soldering F-row keys',
    },
    {
      original: require('./kinesis1_5.jpg'),
      description: 'Soldering thumb clusters',
    },
  ];
export const Kinesis2_images= [
    {
      original: require('./kinesis2_3.jpg'),
      description: 'Box White switches + O-rings upgrade',
    },
    {
      original: require('./kinesis2_1.jpg'),
      description: 'Modded Advantage2 ',
    },
    {
      original: require('./mag_connector.jpg'),
      description: 'Magnetic connectors',
    },
    {
      original: require('./keyboards_fast_swap.jpg'),
      description: 'Installed connectors',
    },
  ];