import MyGallery from "../../../components/ImgGallery";
import { motion } from 'framer-motion';
import { CardMedia, Grid } from '@mui/material'
import "../projects.css"
import {Benevity_images} from "../../../images/projects/taptodonate/Benevity_images";

export default function TapToDonate() {
    return (<section className="projects">
        <h3 className="pageTitle">Tap To Donate</h3>
        <motion.div
            initial={{ x: "-30vw", opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.4, ease: "easeInOut" }}
        >

            {/* <button type="button" onClick={handleBack}>back</button> */}
            <div className="project-details">

            {/* <div className="project-details"> */}
            <div>
                <Grid
                    container
                    direction="row-reverse"
                >
                    <Grid items xs={12} l={7} >
                        <div className='image'>
                            <CardMedia
                                sx={{
                                    objectFit: "contain"
                                }}
                                component="img"
                                height="300"
                                image={require("../../../images/projects/taptodonate/Laptop_Tablet_WisePOS_Mockup.png")}
                                alt="Tap to donate - Stripe WisePOS"
                                align-items="center"
                            />
                        </div>
                    </Grid>

                    <Grid items xs={12} l={5} >
                        <div className="card-content">
                            <p>While working at Benevity, I had the opportunity to architect and develop a comprehensive system for collecting in-person donations and provisioning a fleet of Stripe POS (Point of Sale) terminals. This project aimed to streamline the configuration and deployment of terminals, enabling seamless in-person donation processing for various organizations.</p>
                        </div>
                    </Grid>
                    <div>
                        <h4>Key Responsibilities:</h4>
                        <p>
                            <b>1.	Web-based Terminal:</b> I designed and developed a web-based (JavaScript and ReactJS) terminal to integrate with Stripe's infrastructure, allowing secure and efficient processing and capturing of in-person donations. At core, this integration communicated with Stripe WisePOS reader to handle and capture payment transactions. Depending on the mode selected, terminal would allow for different amount of interactivity from the donor, ranging from fully preset and anonymous to a kiosk style mode where donor could search for a charity enter custom donation amount and request a receipt.
                        </p>
                        <p>
                            <b>2.	Administrator Dashboard:</b> I designed and developed a user-friendly web admin panel using JavaScript and ReactJS. This panel allowed administrators to remotely configure terminals and deploy Stripe POS readers. It provided an intuitive interface for monitoring donations, managing terminal configurations, and monitoring terminal statuses.
                        </p>
                        <p>
                            <b> 3.	Relational Database Schema Design:</b> Using MySQL Workbench, I carefully modeled and defined the relational database schema. This schema efficiently stored terminal information, including configurations, deployment status, and transaction data. The designed schema facilitated data retrieval and ensured data integrity.
                        </p>
                        <p>
                            <b>4.	Serverless Backend Setup:</b> To support the terminal provisioning system, I leveraged AWS services to create a scalable and robust backend. I utilized AWS Lambda for serverless computing, API Gateway for managing REST APIs, and AWS RDS (Relational Database Service) for storing data. Additionally, I incorporated other auxiliary AWS services as required. I utilized AWS Simple Queue Service (SQS) to facilitate the generation and sending of email receipts to donors.
                        </p>
                    </div>
                </Grid>
            </div>
            <div>
                <MyGallery images = {Benevity_images}></MyGallery>
            </div>

            </div>
        </motion.div>
    </section>)
}
