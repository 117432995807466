import MyGallery from "../../../components/ImgGallery";
import { motion } from "framer-motion";
import { CardMedia, Grid } from "@mui/material";
import "../projects.css";
import { Genset_Images } from "../../../images/projects/genset/Genset_Images";

export default function Genset() {
  return (
    <section className="projects">
      <h3 className="pageTitle">Diesel Generator Conversion</h3>
      <motion.div
        initial={{ x: "30vw", opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.4, ease: "easeInOut" }}
      >
        <div className="project-details">
          <div>
            <Grid container direction="row-reverse">
              <Grid items xs={12} l={5}></Grid>
              <div>
                <p>
                  Another motorhome project that involves the conversion of a light
                  tower generator for use in an RV.
                </p>
                <h4>The Challenge</h4>
                <p>
                  Several parts from the original Onan generator were missing,
                  and piecing it back together financially wasn't viable. Buying
                  a new one wasn't the best option either, as they tend to be
                  very expensive, even used ones go for over $10k. Instead, a
                  light tower generator was purchased with the same Kubota D1105
                  3-cylinder engine as in Onan.
                </p>
                <p>
                  The issue with the light tower generator is that it lacks a
                  speed controller and runs at full speed at all times. While
                  it's fine with a constant load, with a transient load, the
                  throttle needs constant adjustment to maintain a consistent
                  RPM of 1800rpm to sustain 110v at 60hz.
                </p>

                <h4> The Solution:</h4>
                <p>
                  <b>1. Speed governor:</b> Installing a speed governor on a
                  Kubota engine to maintain a consistent RPM for a generator is
                  a common practice to ensure stable and reliable power output.
                  A speed governor helps regulate the engine speed under
                  different load conditions, preventing it from running too fast
                  or too slow.
                </p>
                <p>
                  Installation of an actuator that links to the injection pump
                  throttle arm was easy, as the engine already had mounting
                  points for it. However, installing the magnetic pickup (MPU)
                  sensor was a different story. The flywheel housing didn't have
                  a hole for it, and the only flat surface was in an awkward
                  position to be drilled and tapped. The resulting position was
                  not perfectly aligned with flywheel teeth, but after testing
                  with MPU, it was able to pick up the signal just fine.
                </p>
                <p>
                  <b>2. Genset Controller:</b> A genset controller plays a
                  crucial role in the automation and monitoring of a generator.
                  It helps manage the start, stop, and protection functions of
                  the generator, ensuring proper operation and performance. The
                  main criteria when choosing a genset controller were a simple
                  interface, compatibility with sensors, and most importantly,
                  an input for remote start, as well as an output for glow
                  plugs. The Mebay DC30C fit the role perfectly.
                </p>
                <p>
                  <b>3. Frame and Enclosure:</b> The frame was welded together
                  with steel angles, and the enclosure was cut to size and built
                  from the original light tower enclosure. To reduce noise, the
                  enclosure was further insulated with soundproofing material.
                </p>
                <p>
                  Due to limited space, the radiator was moved to a different
                  position, and the hydraulic fan was replaced with an electric
                  one. Additionally, the straight thermostat cover was replaced
                  with a 90-degree one to further lower the total height of the
                  genset.
                </p>
                <p>
                  <b>4. Integration with RV:</b> After installing it into the
                  motorhome, it was tested with different loads. One problem
                  arose with remote start. The genset controller's remote
                  function would only operate when used with latched switches.
                  However, the motorhome had multiple momentary switches for the
                  genset, and the auto start Xantrex system would also send
                  momentary signals to start and stop. The solution I came up
                  with was to directly solder remote switches to the start and
                  stop buttons on the genset controller, instead of trying to
                  make it work with the latched built-in input on the genset
                  controller.
                </p>
              </div>
            </Grid>
          </div>
          <div>
            <MyGallery images={Genset_Images}></MyGallery>
          </div>
        </div>
      </motion.div>
    </section>
  );
}
