export const Genset_Images= [
    {
      original: require('./genset.jpg'),
      description: 'Kubota engine generator',
    },
    {
      original: require('./onan.jpg'),
      description: 'Old Onan genset',
    },
    {
      original: require('./speed_actuator.jpg'),
      description: 'Speed governor and Throttle actuator',
    },
    {
      original: require('./mebay.jpg'),
      description: 'Mebay DC30D genset controller',
    },
    {
      original: require('./frame.jpg'),
      description: 'Frame',
    },
    {
      original: require('./relays.jpg'),
      description: 'Relays',
    },
  ];