import ImageGallery from "react-image-gallery";
import "./ImgGallery.css";

export default function ImgGallery({images}) {

  return (
  <div>
    <ImageGallery
      items={images}
      showPlayButton={false}
      showBullets={true}
      showThumbnails={false}
      // thumbnailHeight={'10'}
      // thumbnailWidth={{height:200}}
      // showIndex={true}
    ></ImageGallery >

  </div>
  );
}