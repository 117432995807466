import NavLinks from "./NavLinks";
import myLogo from "../images/sc-white.png"

export default function Header(){
  return (
    <>
    <header className="header"> 
      <img src={myLogo} width ="100" height="100"  ></img>
      <NavLinks />
    </header>
    </>
  );
};