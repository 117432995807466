import Bike_Photo from "../../images/Bike_Photo.jpg";
import { motion } from "framer-motion";
import SocialIcons from "../../components/SocialIcons";
import {Grid,CardMedia} from "@mui/material";
import{borders} from "@mui/system";
import "./about.css";
export default function About() {
  const styles = {
    icon: {
      textDecoration: "none",
      fontSize: "22px",
      padding: "10px",
      transition: "0.2s ease-in",
    },
  };
  return (
    <section className="about" >
      <h3 className="pageTitle">About Me</h3>
      {/* <Grid container className="personalInfo" 
      >
        <Grid items xs={12} lg={6}> 
              <div className='personalImage'>
                <CardMedia
                  sx={{
                    objectFit:"contain",
                  }}
                  component="img"
                  height="450"
                  width="450"
                  image={Bike_Photo}
                  alt={'Bike photo'}
                  align-items="center"
                />
              </div>
        </Grid>
        <Grid items xs={12} lg={6}>
              <div className="personalDescription">
                <h4>Serhiy Chaykovskyy - BSc, MEng</h4>
                <p>{"I am a software developer with a passion for coding. On my website, you can find my portfolio of projects, my resume, and my contact information. Please feel free to reach out to me if you have any questions. Thank you for visiting!"}</p>
              </div>
        </Grid>
      </Grid> */}
      {/* ____ */}


      <div className="container">
        <div className="row">
          <motion.div
            className="personalImage col-12 col-lg-4"
            // ref={ref}
            initial={{ x: "-10vw", opacity: 0 }}
            // animate={inView ? { x: 0, opacity: 1 } : { x: "-10vw", opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.4, ease: "easeInOut" }}
          >
            <img src={Bike_Photo} alt={"Serhiy Chaykovskyy"} />
          </motion.div>

          <motion.div
            className="personalInfo col-12 col-lg-8"
            // ref={ref}
            initial={{ x: "10vw", opacity: 0 }}
            // animate={inView ? { x: 0, opacity: 1 } : { x: "10vw", opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.4, ease: "easeInOut" }}
          >
            <div className="contentContainer">
              <h4>Serhiy Chaykovskyy - BSc, MEng</h4>
              <div className="contentDescription">
                <p>{"I am a software developer with a passion for coding. On my website, you can find my portfolio of projects, my resume, and my contact information. Please feel free to reach out to me if you have any questions. Thank you for visiting!"}</p>
              </div>
              <div style={styles.SocialIcons}>
                <div className="row">
                </div>
              </div>
            </div>
          </motion.div>
        </div>
        <section className="contact">
          <SocialIcons />
        </section>
      </div>
    </section>
  );
};