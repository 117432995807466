import { useState, useEffect } from "react";
import resume from "../images/Software_Resume_Serhiy_Chaykovskyy.pdf";
export default function SocialIcons(){
  const styles = {
    icon: {
      textDecoration: "none",
      fontSize: "25px",
      padding: "10px",
      transition: "0.2s ease-in",
    },
  };

  // const [downloading, setDownloading] = useState(false);

  // useEffect(() => {
  //   setDownloading(false);
  // }, [downloading]);
  // const handleDownload = () => {
  //   setDownloading(true);
  //   const link = document.createElement("a");
  //   link.href = resume;
  //   link.download = "Software_Resume_Serhiy_Chaykovskyy";
  //   link.onload = () => {
  //     link.remove();
  //     setDownloading(false);
  //   };
  //   document.body.appendChild(link);
  //   link.click();
  // };
  return (
    <div className="socialIcons" style={styles.socialIcons}>

      <a className="icon" style={styles.icon} href="https://drive.google.com/drive/folders/16_0Y5YD5VZwe4R22hbgy23WA_8SWL9OQ?usp=sharing">
      {/* <a className="icon" style={styles.icon} ick={handleDownload}> */}
        <i className="fa-regular fa-file-lines" aria-hidden="true" title="Serhiy's Resume"></i>
      </a>
      <a className="icon" style={styles.icon} href="https://www.linkedin.com/in/chaykovskyy/">
        <i className="fa-brands fa-linkedin" aria-hidden="true" title="Serhiy's LinkedIn Profile"></i>
      </a>
      <a className="icon" style={styles.icon} href="https://github.com/schaykovskyy">
        <i className="fa-brands fa-github" aria-hidden="true" title="Serhiy's GitHub Profile"></i>
      </a>
    </div>
  );
};