export const AE_images= [
//   {
//     original: require('./ae_0.png'),
//     description: 'Motorhome fender',
//   },
  {
    original: require('./ae_2.jpg'),
    description: 'Before repair',
    thumbnail:require("./ae_2 (Custom).jpg"),
  },
  {
    original: require('./ae_1.jpg'),
    description: 'Completed bumper and fender repair',
    thumbnail:require("./ae_1 (Custom).jpg"),
  },
  {
    original: require('./ae_11.jpg'),
    description: 'Installed fender',
    thumbnail:require("./ae_11 (Custom).jpg"),
  },
  {
    original: require('./ae_3.jpg'),
    description: 'Positive foam mold',
    thumbnail:require("./ae_3 (Custom).jpg"),
  },
  {
    original: require('./ae_4.jpg'),
    description: 'First layer fiberglass layup',
    thumbnail:require("./ae_4 (Custom).jpg"),
  },
  {
    original: require('./ae_5.jpg'),
    description: 'Bondoed and primed for painting',
    thumbnail:require("./ae_5 (Custom).jpg"),
  },
  {
    original: require('./ae_6.jpg'),
    description: 'Finished bumper',
    thumbnail:require("./ae_6 (Custom).jpg"),
  },
  {
    original: require('./ae_7.jpg'),
    description: 'Fender layup',
    thumbnail:require("./ae_7 (Custom).jpg"),
  },
  {
    original: require('./ae_8.jpg'),
    description: 'Bondo application',
    thumbnail:require("./ae_8 (Custom).jpg"),
  },
  {
    original: require('./ae_9.jpg'),
    description: 'Primed fender',
    thumbnail:require("./ae_9 (Custom).jpg"),
  },
  {
    original: require('./ae_10.jpg'),
    description: 'Finished fender',
    thumbnail:require("./ae_10 (Custom).jpg"),
  },
];