import { motion } from "framer-motion";

export default function MyContactInfo(){
  return (
    <motion.div
      className="contactInfo"
      initial={{ x: "-10vw", opacity: 0 }}
      animate={{x:0,opacity:1}}
      transition={{ duration: 0.4, ease: "easeInOut" }}
    >
      <h3 className="contentTitle">Contact Information</h3>
      <p className="infoDescription">Whether you have a question, want to start a project or simply want to connect. Feel free to send me a message.</p>
      <ul className="listInfo">
        <li>
          <div className="personalContactInfo">
            <span className="infoIcon">
              <i className="icon fa-solid fa-user"></i>{" "}
            </span>
            <div className="mediaWrap">
              <h6 className="infoType">Name</h6>
              <span className="infoValue">Serhiy Chaykovskyy</span>
            </div>
          </div>
        </li>
        <li>
          <div className="personalContactInfo">
            <span className="infoIcon">
              <i className="icon fa-solid fa-location-pin "></i>{" "}
            </span>
            <div className="mediaWrap">
              <h6 className="infoType">Location</h6>
              <span className="infoValue">Calgary, AB, Canada</span>
            </div>
          </div>
        </li>
        <li>
          <div className="personalContactInfo">
            <span className="infoIcon">
              <i className="icon fa-solid fa-envelope "></i>{" "}
            </span>
            <div className="mediaWrap">
              <h6 className="infoType">Email</h6>
              <span className="infoValue">
                <a href={`mailto:serhiy.chaykovskyy@ucalgary.ca`}>serhiy@chaykovskyy.dev</a>
              </span>
            </div>
          </div>
        </li>
      </ul>
    </motion.div>
  );
};
