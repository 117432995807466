import SocialIcons from "../../components/SocialIcons";
import LetsChatForm from "./LetsChatForm";
import MyContactInfo from "./MyContactInfo";
import "./contact.css";
export default function Contact () {
  return (
    <section className="contact">
      <h3 className="pageTitle">Contact</h3>
      <div className="contactWrap container">
        <div className="row">
          <div className="col-12 col-lg-6">
            <MyContactInfo />
          </div>
          <div className="col-12 col-lg-6">
            <LetsChatForm />
          </div>
        </div>
      </div>
      <SocialIcons />
    </section>
  );
};

