export const Benevity_images = [
  {
    original: require('./dashboard_donations.png'),
    description: 'Dashboard: donations history',
  },
  {
    original: require('./dashboard_create_new_config.png'),
    description: 'Dashboard: create new config',
  },
  {
    original: require('./terminal_anonymous_donation.png'),
    description: 'Terminal: anonymous donation',
  },
  {
    original: require('./terminal_succesful_donation.png'),
    description: 'Terminal: successful donation',
  },
];